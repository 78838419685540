import * as React from 'react';
import {
    DataGrid,
    getGridStringOperators,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import GridToolbar from 'components/GridToolbar';
import ContentHeader from 'components/ContentHeader';
import ListPagination from 'components/ListPagination';
import { useSnackbar } from 'contexts';
import { useList } from "hooks/useList";
import { getUsers, getRoles, decodePassword } from "services";
// Used for searching in the data_grid
// import CustomChipArray from 'components/CustomChipArray'; 
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import { Button } from '@mui/material';
import { copyToClipboard } from 'utils';
import ListQuickSearch from 'components/ListQuickSearch';
import FilterList from './FilterList';
import Select from 'components/RoleSelect';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = { includeTotal: true, include: ["role", "client"] };

export default function UserSearch() {
    const classes = useStyles();

    const snackbar = useSnackbar();

    const [roles, setRoles] = React.useState([]);
    const [clients, setClients] = React.useState([]);

    const {
        loading,
        items,
        total,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getUsers,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "users",
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value , name } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value, [`${columnField}_label`]: name, });
        } else {
            const newFilters = { ...BaseFilters, ...filters };
            delete newFilters[[columnField]];
            setFilters(newFilters);
        }
    };
    const handleRoleChange = React.useCallback((role) => {
        if (!role) {
            handleFilterChange({
                items: [{ columnField: 'role', value: undefined }]
            });
        }
        handleFilterChange({
            items: [{ columnField: 'role', value: role._id }]
        });
    });

    const handleClientChange = React.useCallback((client) => {
        if (!client) {
            handleFilterChange({
                items: [{ columnField: 'client', value: undefined , name : undefined}]
            });
        }
        handleFilterChange({
            items: [{ columnField: 'client', value: client._id , name : client.name }]
        });
    });

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const columns = React.useMemo(() => {

        return [
            {
                field: '_id',
                headerName: 'ID',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'role',
                headerName: 'Role',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                valueOptions: roles.map(role => ({
                    value: role._id, label: role.name
                })),
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
                valueGetter: (params) => params?.row?.role?.name || '',
            },
            {
                field: 'client',
                headerName: 'Client',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                valueOptions: clients.map(client => ({
                    value: client._id, label: client.name
                })),
                filterOperators: getGridSingleSelectOperators().filter(
                    (operator) => operator.value === 'is',
                ),
                valueGetter: (params) => params?.row?.client?.name || '',
            },
            {
                field: 'contact',
                headerName: 'Contact',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'password',
                headerName: 'Password',
                width: 160,
                headerClassName: classes.tableHeader,
                renderCell: ({ row }) => {
                    return <Button
                        startIcon={<KeyOutlinedIcon />}
                        onClick={() => decryptByUserId(row._id)}
                    />
                }
            },
        ];
    }, [classes.tableHeader, roles, clients]);

    React.useEffect(() => {
        (async () => {
            try {
                const { roles } = await getRoles();
                const { users } = await getUsers({roleNames: ['CLIENT', 'SEP_CLIENT']});

                setClients(users);
                setRoles(roles);
            } catch (error) {
                console.log(error);
                snackbar.showSnackbar("Unable to get role options", "error");
            }
        })();
    }, []);

    const decryptByUserId = async (userId) => {
        try {

            const { data: password } = await decodePassword(userId);

            copyToClipboard(password);

            snackbar.showSnackbar("Password copied!", "success");
        } catch (error) {
            console.log(error);
            snackbar.showSnackbar("Unable to get password ", "error");
        }
    }

    return (
        <Box>
            <ContentHeader
                title="Search Users"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} value={filters[['name']]} />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by email"
                        field="email"
                        value={filters[['email']]}
                    />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by contact"
                        field="contact"
                        value={filters[['contact']]}
                    />
                    <ListQuickSearch
                        onFilterChange={handleFilterChange}
                        label="Search by ID"
                        field="_id"
                        value={filters[['_id']]}
                    />
                    <Select 
                      onChange={handleRoleChange} defaultValue={filters[['role']]} 
                      label='choose a role' options={roles}
                    />
                    <Select 
                      onChange={handleClientChange} defaultValue={filters[['client']]} 
                      label='choose a client' options={clients}
                    />
                </FilterList>
            </ContentHeader>
            <div style={{ height: '70vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
        </Box>
    );
}