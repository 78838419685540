import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getUsers } from "services/users";
import { FormHelperText, Typography } from '@mui/material';
import { SubscriptionModel } from './forms/constants';

const useStyles = makeStyles((theme) => ({
  error: {
    marginLeft: 16,
  },
  root: {
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Inter', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
}));

function ClientSelect({
  defaultValue, onChange = (client) => { }, error = "", sepClients, filter = [], disabled = false
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [clients, setClients] = React.useState([]);

  const handleClientChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  }
  React.useEffect(() => {
    (async () => {
      const { users } = await getUsers({
        roleNames: sepClients ? "SEP_CLIENT" : "CLIENT",
      });
      
      const activeClients = users.filter(user => !user.deactivated && (!filter || !filter.includes(user._id)));
      setClients(activeClients);
    })();
  }, [sepClients]);

  React.useEffect(() => {
    const client = clients.find(c => c._id === defaultValue);

    if (client) {
      setValue(client);
      onChange(client);
    }
  }, [clients , defaultValue]);

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={clients}
        value={value}
        onChange={handleClientChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Choose a ${sepClients ? "SEP" : "InPrep"} Client`}
            className={classes.root}
            error={!!error}
          />
        )}
        getOptionLabel={(user) => {
          const subModel = user?.metadata?.subscriptionModel ?? "";
          const subName = SubscriptionModel.find((el) => el._id === subModel)?.name ?? "";
          return `${user.name} (${user.email}) (${subName})`
        }}
        renderOption={(props, option) => {
          const subModel = option?.metadata?.subscriptionModel ?? "";
          const subName = SubscriptionModel.find((el) => el._id === subModel)?.name ?? "";
          return (
            <Typography
              {...props}
              component="li"
              sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
            >
              {option.name} ({option.email}) ({subName}) 
              {option._id === process.env.REACT_APP_LANGUIFY_CLIENT_ID ? ' (Global)' : ''}
            </Typography>
          )
        }}
      />
      {!!error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
    </>
  );
}

export function MockClientSelect({
  defaultValue, onChange = (client) => { }, error = "",
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [clients, setClients] = React.useState([]);

  const handleClientChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  }

  React.useEffect(() => {
    (async () => {
      const { users } = await getUsers({
        roleNames: "MOCK_CLIENT",
      });
      setClients(users);
    })();
  }, []);

  React.useEffect(() => {
    const client = clients.find(c => c._id === defaultValue);

    if (client) setValue(client);
  }, [clients, defaultValue]);

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={clients}
        value={value}
        onChange={handleClientChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Choose a Mock Client`}
            className={classes.root}
            error={!!error}
          />
        )}
        getOptionLabel={(user) => `${user.name} (${user.email})`}
        renderOption={(props, option) => (
          <Typography
            {...props}
            component="li"
            sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
          >
            {option.name} ({option.email})
          </Typography>
        )}
      />
      {!!error && <FormHelperText className={classes.error} error>{error}</FormHelperText>}
    </>
  );
}

export default ClientSelect;