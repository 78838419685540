import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from "@mui/styles/makeStyles";
import {
    DataGrid, getGridStringOperators, GridActionsCellItem
} from '@mui/x-data-grid';
import ClientSelect from 'components/ClientSelect';
import ContentHeader from 'components/ContentHeader';
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import GridToolbar from 'components/GridToolbar';
import ListPagination from 'components/ListPagination';
import { useList } from "hooks/useList";
import { getWebhookEvents } from '../../services/webhookEvents';
import { formatDate } from 'utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main, color: "white",
        alignItems: "center"
    }
}));

const BaseFilters = { includeTotal: true };

export default function WebhookEvents() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);
    const {
        loading,
        items,
        total,
        setFilters,
        filters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getWebhookEvents,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "webHookEvents",
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value, name } = items[0];
        if (value) {
            setFilters({ ...BaseFilters, ...filters, [columnField]: value , [`${columnField}_label`]: name,});
        } else {
            setFilters(BaseFilters);
        }
    };

    const handleClientChange = React.useCallback((client) => {
        if (!client) return;

        handleFilterChange({
            items: [{ columnField: 'client', value: client._id , name : client.name}]
        });
    }, []);

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'status',
                headerName: 'Status',
                flex: 2,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
                valueGetter: ({ row }) => {
                    const response = row?.response;

                    if (response?.statusCode >= 200 && response?.statusCode <= 299) {
                        return 'SUCCESS';
                    }

                    if (response?.statusCode >= 400 && response?.statusCode <= 599) {
                        return 'ERROR';
                    }

                    return 'PENDING';
                },
            },
            {
                field: 'webhookResponse',
                headerName: 'Status Code',
                flex: 2,
                headerClassName: classes.tableHeader,
                valueGetter: ({ row }) => {
                    const response = row?.response;

                    if (response?.statusCode)
                        return response?.statusCode;
                    else
                        return '-';
                },
            },
            {
                field: 'createdAt',
                headerName: 'Created at',
                headerClassName: classes.tableHeader,
                filterable: false,
                flex: 1,
                valueGetter: ({ row: { createdAt } }) =>
                    formatDate(createdAt)
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="View"
                        onClick={(e) => setActiveItemId(params.row._id)}
                    />
                ],
            },
        ];
    }, [classes.tableHeader]);

    return (
        <Box>
            <ContentHeader
                title="WebhookEvents"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <Box width={350}>
                    <ClientSelect onChange={handleClientChange} sepClients />
                </Box>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <EntityViewDialog
                open={!!activeItemId}
                getEntity={(id) => items.find(item => item._id === id)}
                title="Webhook event details"
                entityId={activeItemId}
                onClose={() => setActiveItemId(null)}
            />
        </Box>
    );
}