import * as React from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import { Snackbar } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import GridToolbar from 'components/GridToolbar';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { useList } from "hooks/useList";
import { getListActions, checkCreatePermission } from "utils/listActions";
import { getBatches} from 'services/batch';
import FilterList from './FilterList';
import ListQuickSearch from 'components/ListQuickSearch';
import Select from 'components/RoleSelect';
import { getUsers } from 'services';
import { FormCheckbox } from 'components/CheckBox';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    includeTotal: true, include: 'client'
};

export default function Batches() {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = React.useState(null);
    const [clients, setClients] = React.useState([]);
    const [forPathway, setForPathway] = React.useState(false);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getBatches,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 10,
        itemName: "batches",
        // deleteItemById: deleteBatchById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value, name } = items[0];
        if (value)
            setFilters({ ...BaseFilters, [columnField]: value, [`${columnField}_label`]: name, });
        else
            setFilters(BaseFilters);
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    React.useEffect(() => {
        (async () => {
            try {
                const { users } = await getUsers({roleNames: ['CLIENT']});

                setClients(users);
            } catch (error) {
                console.log(error);
                Snackbar.showSnackbar("Unable to get role options", "error");
            }
        })();
    }, []);

    const handleClientChange = React.useCallback((client) => {
        if (!client) {
            handleFilterChange({
                items: [{ columnField: 'client', value: undefined , name:undefined }]
            });
        }
        handleFilterChange({
            items: [{ columnField: 'client', value: client._id , name : client.name }]
        });
    });

    const handlePathwayChange = (checked) => {
        setForPathway(checked);
        handleFilterChange({ items: [{ columnField: 'forPathway', value: checked }] });
    };

    const columns = React.useMemo(() => {
        return [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
                renderCell: ({ row }) => (
                    row.forPathway ? `${row.name} (Pathway)` : row.name
                ),
            },
            {
                field: 'client',
                headerName: 'Client',
                flex: 1,
                renderCell: ({row})=> row?.client?.name,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'studentCount',
                headerName: 'Students',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'templateCount',
                headerName: 'Templates',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'createdAt',
                headerName: 'Added on',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 80,
                headerClassName: classes.tableHeader,
                getActions: (params) =>
                    getListActions(params.id, editItem, setActiveItemId, "Batch")
            },
        ];
    }, []);

    return (
        <Box>
            <ContentHeader
                title="Batches"
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                {checkCreatePermission("Batch") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
                <FilterList>
                    <ListQuickSearch onFilterChange={handleFilterChange} />
                    <Select 
                      onChange={handleClientChange} defaultValue={filters[['client']]} 
                      label='choose a client' options={clients}
                    />
                    <FormCheckbox
                        checked={forPathway} 
                        onChange={handlePathwayChange} 
                        name="pathwayCheckbox" 
                        label="Pathway Batches" 
                    />
                </FilterList>
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    pagination
                    autoPageSize
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row._id}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This batch will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete batch"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
        </Box>
    );
}